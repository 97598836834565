(function () {
  'use strict';

  class ViewCtrl {
    constructor($rootScope, $scope, $document, Utils, CanvasZoom) {
      let vm = this;
      vm.ctrlName = 'ViewCtrl';
      vm.$rootScope = $rootScope;
      vm.$document = $document;
      vm.Utils = Utils;
      vm.CanvasZoom = CanvasZoom;
      vm.$scope = $scope;
      vm.$scope.$watch('view.$scope.resource', function (newResource) {
        if (newResource) {
          vm.resource = newResource;
        }
      }, true);
    }
    zoom(e) {
      const vm = this;
      vm.CanvasZoom.show(e, vm.resource);
    }
    toggleAnswer(item, list) {
      const idx = list.indexOf(item);
      if (idx > -1) {
        list.splice(idx, 1);
      } else {
        list.push(item);
      }
    }
    checkAnswers(poll_question_option) {
      if (angular.isUndefined(poll_question_option.answers)) {
        poll_question_option.answers = [];
      }
    }
    exists(item, arr) {
      return arr.indexOf(item) > -1;
    }
    initCanvas(resource) {
      let vm = this;
      vm.canvas = vm.$document[0].getElementById('canvas-student');
      vm.ctx = vm.canvas.getContext('2d');
      vm.drawCanvas(resource);
    }
    drawCanvas(resource) {
      let vm = this,
          img = new Image();
      img.crossOrigin = 'Anonymous';
      img.onload = function () {
        vm.ctx.drawImage(img, 0, 0);
      };
      img.src = resource.media.url;
    }
    clear(resource) {
      let vm = this;
      vm.$rootScope.$emit('canvasTouched', false);
      vm.ctx.clearRect(0, 0, 800, 600);
      vm.drawCanvas(resource);
    }
  }

  /**
   * @ngdoc object
   * @name components.controller:ViewCtrl
   *
   * @description
   *
   */
  angular
    .module('components')
    .controller('ViewCtrl', ViewCtrl);
}());
